import {
  API_CASSA,
} from "../constants/apiBaseUrl";

import { AjaxService } from "../utils/AjaxService";
export const getAllCompoundList = () => {
  return AjaxService.get(`${API_CASSA}/api/reports/getAllCompounds`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getAllStudyList = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getStudiesByCompound`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getCountryList = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getCountries`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getPatientSubjectChart = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getPatientCharts`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getSiteCharts = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getSiteCharts`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getReportsSummary = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getReportsSummary`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getAllProtocolList = () => {
  return AjaxService.get(`${API_CASSA}/api/reports/getAllProtocols`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllCompoundsForInventorySummaryReport = () => {
  return AjaxService.get(`${API_CASSA}/api/reports/getAllCompoundsForInventorySummaryReport`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllDepotList = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllDepots`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getAllEnrollmentReportsByPage = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllEnrollmentReportsByPage`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getAllLotExpiryReportsByPage = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllLotExpiryReportsByPage`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getTotalEnrollmentsCount = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getTotalEnrollments`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getTotalExpiryCount = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getTotalExpiry`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllEnrollmentReports = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllEnrollmentReports`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllLotExpiryReports = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllLotExpiryReports`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllIBPTableNamesList = () => {
  return AjaxService.get(`${API_CASSA}/api/reports/getAllIBPTableNames`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getIBPTableData = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllIBPTableDetails`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllIBPTableDetailsCount = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllIBPTableDetailsCount`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDistinctProtocolsByCompoundForISR = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getDistinctProtocolsByCompoundForISR`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//get all depots with id and name
export const getAllDepotAndDepotIDList = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllDepotIdAndNames`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//get all depots with id and name
export const getAllUDEDataForSite360ForTable = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllUDEDataForSite360`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};


export const getAllUDEDataForFGViewForTable = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/reports/getAllUDEDataForFGView`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};